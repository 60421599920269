import React from 'react'
import useBreakpoint from '../customHooks/useBreakpoint'

const Cmp = () => {
  const isMobile = useBreakpoint('sm', false)
  return (
    <div style={{
      minHeight: `calc(100vh - 85px)`,
      position: 'relative',
      justifyContent: isMobile ? 'flex-start' : 'center',
      marginTop: 85,
      padding: 0
    }}>
      Επωνυμία: QUINTESSENTIAL SFT Ι.Κ.Ε.Διακριτικός Τίτλος: QUINTESSENTIAL SFT

      ΑρΓεΜΗ:  145180301000
      ΑΦΜ: 800930149
      Διεύθυνση: ΧΑΜΟΣΤΕΡΝΑΣ 16
      ΤΚ: 11853
      Περιοχή: ΑΘΗΝΑ
      Πόλη: ΑΘΗΝΑ
      Διαχειριστές :
      1. για αόριστη διάρκεια, ο  Δασκαλάκης Αλέξανδρος  του Αντωνίου, κάτοικος Αγίας Παρασκευής, επί της οδού Αναστάσεως αρ. 6. 2. για αόριστη διάρκεια, ο Κοπανίδης Κωνσταντίνος του Χαράλαμπου, κάτοικος Περιστερίου, επί της οδού Κωλέττη, αρ. 22,3. για αόριστη διάρκεια, ο Χαρίσης Σωκράτης του Ηλία, κάτοικος Αμπελοκήπων, επί της οδού Σιθωνίας αρ. 21Οι εταίροι :  1. Ο εταίρος Δασκαλάκης Αλέξανδρος  του Αντωνίου, κάτοικος Αγίας Παρασκευής, επί της οδού Αναστάσεως αρ. 6, ο οποίος για κεφαλαιακή εισφορά αξίας 800 X (8/30) ευρώ έλαβε 800 X (8/30) μερίδια
      2. Ο εταίρος Κοπανίδης Κωνσταντίνος του Χαράλαμπου, κάτοικος Περιστερίου, επί της οδού Κωλέττη, αρ. 22, ο οποίος για κεφαλαιακή εισφορά αξίας 800 X (8/30) ευρώ έλαβε 800 X (8/30) μερίδια.3. Ο εταίρος Χαρίσης Σωκράτης του Ηλία, κάτοικος Αμπελοκήπων, επί της οδού Σιθωνίας αρ. 21, ο οποίος για κεφαλαιακή εισφορά αξίας 800 X (8/30) ευρώ έλαβε 800 X (8/30) μερίδια.4. Ο εταίρος Γεώργιος - Πέτρος Χαντζάρας του Θεοδώρου, κάτοικος Αγίας Παρασκευής, επί της οδού Άγγελου Σικελιανού αρ. 3, ο οποίος για κεφαλαιακή εισφορά αξίας 300X (10%) ευρώ έλαβε 300X (10%) μερίδια.5. Βασίλειος - Παύλος Χαντζάρας του Θεοδώρου, κάτοικος  Αγίας Παρασκευής, επί της οδού Άγγελου Σικελιανού αρ. 3, ο οποίος για κεφαλαιακή εισφορά αξίας 300X (10%) ευρώ έλαβε 300X (10%) μερίδια.Τηλέφωνο Επικοινωνίας: +30 211 1823265 Κεφάλαιο: €3.000, κεφαλαιουχική εισφορά .
    </div>
  )
}

export default Cmp

